const componentsConfig = {
  Divider: {
    baseStyle: {
      opacity: 1
    }
  },
  Heading: {
    baseStyle: {
      margin: 0,
      padding: 0,
      color: "white",
      textTransform: "uppercase"
      // backgroundColor: "native-red"
    },
    // Can't overide "as" Props
    // Read More: https://github.com/chakra-ui/chakra-ui/issues/3287
    variants: {
      h1: {
        fontSize: ["1.5em", "2.25em", "3.75em"]
      },
      h2: {
        fontFamily: "Nunito, sans-serif",
        fontSize: ["1.5em", "2.5em", "3.25em"]
      },
      h3: {
        fontSize: ["1.25em", "1.75em", "2.5em"]
      },
      h4: {
        fontFamily: "Nunito, sans-serif",
        fontSize: ["1.25em", "1.75em", "2.5em"]
      },
      h5: { fontSize: ["0.75em", "1em", "1.25em"] },
      h6: {
        fontFamily: "Nunito, sans-serif",
        fontSize: ["0.75em", "1em", "1.25em"]
      }
    },
    defaultProps: {
      variant: "h1"
    }
  },
  Text: {
    baseStyle: {
      color: "white",
      fontFamily: "Nunito, sans-serif",
      fontSize: "lg"
    }
  },
  Button: {
    baseStyle: {
      rounded: 0,
      border: "white",
      borderWidth: ["2.5px", "5px"],

      textTransform: "uppercase",

      fontWeight: "600",
      letterSpacing: ".25em",
      fontFamily: "Cardo, serif"
    },
    variants: {
      solid: {
        backgroundColor: "native-red",
        color: "white",
        _hover: {
          backgroundColor: "native-red",
          color: "white",
          opacity: 0.75
        }
      },
      outline: {
        paddingY: "2.25em",
        paddingX: "3em",
        fontSize: [".5em", ".75em", "1.25em"],
        _hover: {
          color: "black",
          bgColor: "white",
          fontWeight: "400"
        }
      },
      "menu-item": {},
      icon: {
        rounded: "full",
        backgroundColor: "transparent",
        _hover: {
          backgroundColor: "black",
          color: "white",
          opacity: 0.75
        }
      }
    }
  }
};

export default componentsConfig;
